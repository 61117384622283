import { Breadcrumb as UIBreadcrumb } from '@faststore/ui'
import { memo } from 'react'
import Link from 'src/components/ui/Link'
import type { BreadcrumbProps as UIBreadcrumbProps } from '@faststore/ui'

import * as S from './styles'
import './breadcrumb.scss'
import Icon from '../Icon'

type ItemElement = {
  item: string
  name: string
  position: number
}
export interface BreadcrumbProps extends UIBreadcrumbProps {
  breadcrumbList: ItemElement[]
  page?: string
}

function Breadcrumb({ breadcrumbList, page }: BreadcrumbProps) {
  // useEffect(() => {
  //   const $li = document.querySelectorAll('ol[data-breadcrumb-list] > li > a')

  //   $li.forEach((item) => {
  //     const width = item.clientWidth
  //     const itemLi = item as HTMLElement

  //     itemLi.style.width = `${width + 12}px`
  //   })
  // }, [])

  return (
    <S.BreadContainer>
      <UIBreadcrumb
        divider={<Icon name="dropdown-arrow" width={9} height={6} />}
      >
        <Link aria-label="miess" className="first" to="/">
          Miess
          {/* <HouseIcon size={18} weight="bold" /> */}
        </Link>
        {breadcrumbList.map(({ item, name }, index) => {
          return breadcrumbList.length === index + 1 ? (
            page === 'search' ? (
              <h1 key={String(index)}>{name}</h1>
            ) : (
              <span key={String(index)}>{name}</span>
            )
          ) : (
            <Link to={item} key={String(index)}>
              {name}
            </Link>
          )
        })}
      </UIBreadcrumb>
    </S.BreadContainer>
  )
}

// const Breadcrumb = ({ breadcrumbList }: BreadcrumbProps) => (
//   <>
//     <BaseBreadcrumb breadcrumbList={breadcrumbList} />
//     <BaseBreadcrumb breadcrumbList={breadcrumbList} isDesktop />
//   </>
// )

export default memo(Breadcrumb)
