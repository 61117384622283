import { mediaMax } from 'src/styles/utils/mediaMax'
import { containerCenter } from 'src/styles/utils/containerCenter'
import styled, { css } from 'styled-components'

export const BreadContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  font-size: 12px;

  ${containerCenter}

  /* nav {
    height: 100% !important;
  } */
  a {
    color: ${({ theme }) => theme.colors.grey_2} !important;

    font-weight: 500;
    /* height: 16px; */

    text-decoration: none !important;
    box-sizing: border-box;

    transition: transform 0.1s;
    padding: 0 !important;

    margin-left: 8px;
    margin-right: 8px;

    &:hover {
      transform: scale(1.05);
    }
  }

  span[data-breadcrumb-item='current'] {
    color: ${({ theme }) => theme.colors.primary_0} !important;
    font-weight: 700;
    line-height: 1.5;
    margin-left: 8px;
    margin-right: 8px;
  }

  h1[data-breadcrumb-item='current'] {
    color: ${({ theme }) => theme.colors.primary_0} !important;
    font-weight: 700;
    line-height: 1.5;
  }

  .icon__container {
    transform: rotate(-90deg);
    min-width: 9px;
    min-height: 6px;
  }

  ol {
    display: flex;
  }

  li {
    display: flex;
    align-items: center;
  }

  .first {
    min-width: unset !important;
    margin-right: 8px;
    margin-left: 8px;
  }

  ${mediaMax(
    css`
      min-height: 54px;
      ol {
        flex-wrap: wrap;
        span[data-breadcrumb-item='current'] {
          white-space: unset !important;
        }
      }
    `,
    640
  )}
`
