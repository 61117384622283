import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import InstitutionalText from 'src/components/sections/InstitutionalText'
import Newsletter from 'src/components/sections/Newsletter'

const OursStores: FCC<PageProps> = (props) => {
  const {
    location: { host, pathname },
  } = props

  const title = 'Nossas Lojas - Sex Shop e Lingerie: Atacado e Varejo | Miess'

  const docTitle = 'Nossas Lojas'

  const slug = 'institucionais/AntesCompra/NossasLojas'

  const description =
    'Encontre as melhores Lingeries e Produtos de Sex Shop Atacado e Varejo. Conheça a loja mais completa de produtos eróticos do Brasil.'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />

      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          name: 'Miess Sex Shop',
          description: 'Sex Shop e Loja de artigos eróticos',
          image:
            'https://blog.miess.com.br/wp-content/uploads/2022/03/cropped-logo-miess-p-storie.pnghttps://blog.miess.com.br/wp-content/uploads/2022/03/cropped-logo-miess-p-storie.png',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Rua Cavour, 708',
            addressLocality: 'Vila Prudente',
            addressRegion: 'São Paulo',
            postalCode: '03136-010',
            addressCountry: 'BR',
          },
          telephone: '+55-11-4810-6810',
          email: 'contato@miess.com.br',
          openingHours: 'Mo-Sa 08:00-18:00',
          url: 'https://www.miess.com.br/',
          naics: '453998',
        }}
      />

      {/* Sections */}

      <Breadcrumb
        name={docTitle}
        breadcrumbList={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      <InstitutionalText title="Nossas Lojas">
        <div>
          <p>
            A MIESS é uma loja virtual, mas caso tenha interesse em retirar seu
            pedido em nossa sede, esta opção pode ser escolhida em um dos passos
            para finalização de seu pedido.
          </p>
          <p>
            Durante a escolha do frete selecione a opção{' '}
            <strong>"Retire na loja"</strong> e dê continuidade na finalização
            de seu pedido. Após a confirmação do pagamento, entre em contato com
            nossa equipe e faça o agendamento de sua retirada (conforme
            disponibilidade de horários). Estamos atendendo retirada no local
            somente com o pagamento aprovado e mediante a agendamento. Por
            favor, entre em contato com a nossa equipe pelo telefone (11)
            4810-6810, informando o dia e horário de sua preferência.
          </p>
          <h2>
            <ins>NOSSO ENDEREÇO:</ins>
          </h2>
          <p>Rua Cavour, 708 - Vila Prudente / São Paulo - SP, 03136-010.</p>
        </div>
      </InstitutionalText>
      <Newsletter />
    </>
  )
}

export default OursStores
